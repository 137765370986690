import React, { Fragment, useState, useCallback } from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Collapse from "@kunukn/react-collapse";

export default function Terms({ data }) {
  const page = data.allWpPage.nodes[0];
  const [activeRow, setActiveRow] = useState(0);

  const onClickRow = useCallback((index) => {	
	if (activeRow===index) {
		setActiveRow(false);
	} else {
		setActiveRow(index);
	}
  }, [activeRow]);

  return (
    <Layout className="page-terms">	
      <Seo post={page} />
	  <article>
	    <header className="page-widgets">
	      <Hero widget={{layout : 'small', title: (page.title)}} />
	    </header>
		<div className="internal">
			{
			  page.content && (
				<div className="copy" dangerouslySetInnerHTML={{ __html: page.content }} />
			)}
			
			{/*do we have some accordion content?*/}
			{
			  page.terms?.termsAccordion && page.terms?.termsAccordion.length && (
				<dl className="landbay-accordion">
					{page.terms.termsAccordion.map((row, i) => (
					  <Fragment key={`${i}-item`}>
						<div className={activeRow===i ? 'active' : ''}>{/*ref: https://github.com/whatwg/html/issues/1937*/}
							<dt onClick={() => onClickRow(i)} onKeyDown={() => onClickRow(i)} role="presentation">
							  {row.title}
							</dt>
							<dd><Collapse isOpen={activeRow===i} dangerouslySetInnerHTML={{ __html: row.text }}></Collapse></dd>
						</div>
					  </Fragment>
					))}
			    </dl>
			)}
		</div>
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        ...PageSEOFragment
        title
	    content
		terms {
		  termsAccordion {
		    title
		    text
		  }
		}
      }
    }
  }
`